<template>
  <div class="row h-100 justify-content-center">
    <div class="col-lg-10 col-xl-10 justify-content-start">
      <div class="d-flex flex-column px-4 flex-wrap my-5">
        <!--::Description-->
        <div class="row" v-if="selectedUser.profilePublic || selectedUser.id == this.currentUser.id">
          <div class="col-12">
             <div class="card card-custom  card-shadow mb-3">
              <div class="card-body">
                <div class="d-flex align-items-center mt-4 ml-4"

                >
<!--                  v-if="selectedUser.profilePublic || selectedUser.id == this.currentUser.id"-->
                  <a href="#" class="font-size-h2 text-dark font-weight-bolder mb-1">
                    {{ selectedUser.name}} {{selectedUser.surname}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4">
            <div class="row mb-4">
              <div class="col-md-12">
                <div class="card card-custom shadow-sm">
                  <div class="card-body text-center">

                    <div class="mt-3">
                      <img
                        style="width: 70%;"
                        :src="(selectedUser.avatar && selectedUser.avatar.uri) ? selectedUser.avatar.uri : $imageOptions.defaultAvatarUrl"
                      />
                    </div>

                    <div

                        id="user-roles"
                        class="mt-5">
                      <span v-for="role in roles"
                          :key="role.id">
                          <span class="label label-lg label-light-primary label-inline p-5 mr-2 mb-2">
                            {{ role.name }}
                          </span>
                      </span>
                    </div>
                  </div>
                  <div

                      class="card-body d-flex flex-column mt-0 pt-0">
                    <div class="py-0">
                      <div class="row m-0">

                        <div class="col px-6 py-4">
                          <div class="font-size-sm text-muted font-weight-bold" v-if="selectedUser.age">
                            {{$t("USERDETAIL.ETA")}}
                          </div>
                          <div class="font-size-h3 font-weight-bolder" v-if="selectedUser.age">{{ selectedUser.age }}</div>
                        </div>
                      </div>

                      <div class="row m-0"  v-if="selectedUser.profilePublic || selectedUser.id == this.currentUser.id">
                        <div class="col px-6 py-4">
                          <div class="font-size-sm text-muted font-weight-bold" v-if="selectedUser.educationLevel">
                            {{$t("USERDETAIL.EDUCAZIONE")}}
                          </div>
                          <div class="font-size-h5 font-weight-bolder"  v-if="selectedUser.educationLevel">
                            {{ selectedUser.educationLevel.name }}
                          </div>
                        </div>
<!--                        <div class="col px-6 py-4">
                          <div class="font-size-sm text-muted font-weight-bold" v-if="selectedUser.workerCategory">
                            Lavoro
                          </div>
                          <div class="font-size-h5 font-weight-bolder" v-if="selectedUser.workerCategory">
                            {{ selectedUser.workerCategory.name }}
                          </div>
                        </div>-->
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-8" v-if="selectedUser.profilePublic || selectedUser.id == this.currentUser.id">

            <div class="card mb-3 shadow-sm" v-if="selectedUser.biography">
              <b-button v-b-toggle.collapse-biography class="btn btn-block btn-white text-primary p-4 text-left">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  >
                  <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                  >
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                      d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961
                      C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686
                      20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429
                      12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721
                      L12.2928955,6.70710318 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      ></path>
                      <path
                      d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068
                      C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322
                      C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459
                      C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465
                      14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                      transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      ></path>
                  </g>
                  </svg>
                </span>
                <span class="h4 ml-5 font-size-bolder"> {{$t("USERDETAIL.BIOGRAFIA")}}</span>
              </b-button>
              <b-collapse visible id="collapse-biography">
                <div class="card-body">
                  <div class="row px-5">
                    <div class="col-lg-12 col-sm-12">
                      <p class="card-text ql-editor reset-height h4" v-html="selectedUser.biography"></p>
                      <!-- Necessario per la gestione dell'html inserito dall'editor -->
                      <vue-editor id="input-hidden" placeholder="" :tag="'textarea'" class="bg-light-primary" row="1" hidden></vue-editor>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <!--
            <div class="card mb-3 shadow-sm">
              <b-button v-b-toggle.collapse-budget class="btn btn-block btn-white text-primary p-4 text-left">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  >
                  <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                  >
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                      d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961
                      C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686
                      20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429
                      12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721
                      L12.2928955,6.70710318 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      ></path>
                      <path
                      d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068
                      C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322
                      C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459
                      C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465
                      14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                      transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      ></path>
                  </g>
                  </svg>
                </span>
                <span class="h4 ml-5 font-size-bolder">Budget</span>
              </b-button>
              <b-collapse id="collapse-budget">
                <div class="card-body">
                  <div class="row p-5">
                    <div class="col-lg-6 col-sm-12">
                      <h5>Reddito annuale</h5>
                      <div class="py-4 px-5 h3 text-primary bg-gray-100">{{selectedUser.annualIncome ? selectedUser.annualIncome : '---'}} €</div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <h5>Budget da donare</h5>
                      <div class="py-4 px-5 h3 text-primary bg-gray-100">{{selectedUser.participatoryBudget ? selectedUser.participatoryBudget : '---' }} €</div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            -->

<!--            <div class="card mb-3 shadow-sm" >
              <b-button v-b-toggle.collapse-challenge class="btn btn-block btn-white text-primary p-4 text-left">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  >
                  <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                  >
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                      d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961
                      C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686
                      20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429
                      12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721
                      L12.2928955,6.70710318 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      ></path>
                      <path
                      d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068
                      C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322
                      C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459
                      C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465
                      14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                      transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      ></path>
                  </g>
                  </svg>
                </span>
                <span class="h4 ml-5 font-size-bolder">Obiettivi e interessi</span>
              </b-button>
              <b-collapse id="collapse-challenge">
                <div class="card-body">
                  <div class="row p-5">
                    <div class="col-lg-12 col-sm-12">
                      <template
                        v-if="selectedUser.challenges != null && selectedUser.challenges.length > 0">
                          <div class="row">
                            <div class="col-6 mb-4"
                            v-for="challenge in selectedUser.challenges" :key="challenge.id">
                              <div class="row">
                                <div class="col-4">
                                  <img :src="challenge.icon.uri" :alt="challenge.icon.title" width="100%" />
                                </div>
                                <div class="col-8">
                                  <h6>{{challenge.name}}</h6>

                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <div v-else>
                          <div class="h4">Nessuna challenge abbinata</div>
                        </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>-->
            <div class="card mb-3 shadow-sm" v-if="selectedUser.education || selectedUser.job">
              <b-button v-b-toggle.collapse-education class="btn btn-block btn-white text-primary p-4 text-left">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  >
                  <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                  >
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                      d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961
                      C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686
                      20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429
                      12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721
                      L12.2928955,6.70710318 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      ></path>
                      <path
                      d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068
                      C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322
                      C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459
                      C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465
                      14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      opacity="0.3"
                      transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      ></path>
                  </g>
                  </svg>
                </span>
                <span class="h4 ml-5 font-size-bolder"> {{$t("USERDETAIL.EDULAVORO")}}</span>
              </b-button>
              <b-collapse visible id="collapse-education">
                <div class="card-body">
                  <div class="row p-5">
                    <div class="col-lg-12 mb-7">
                      <h5> {{$t("USERDETAIL.EDUCAZIONE")}}</h5>
                      <div class="card-text ql-editor reset-height h4 font-weight-normal mt-4" v-html="selectedUser.education ? selectedUser.education : '---'"></div>
                    </div>
                    <div class="col-lg-12 mb-7">
                      <h5> {{$t("USERDETAIL.LAVORO")}}</h5>
                      <div class="card-text ql-editor reset-height h4 font-weight-normal mt-4" v-html="selectedUser.job ? selectedUser.job : '---'"></div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <!--::BEGIN Proposte effettuate -->
            <div class="card mb-3 shadow-sm" v-if="userIdeas.length > 0" >
              <b-button v-b-toggle.collapse-idee class="btn btn-block btn-white text-primary p-4 text-left">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                  >
                  <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                  >
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                          d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961
                      C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686
                      20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429
                      12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721
                      L12.2928955,6.70710318 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                      ></path>
                      <path
                          d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068
                      C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322
                      C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459
                      C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465
                      14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                          transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      ></path>
                  </g>
                  </svg>
                </span>
                <span class="h4 ml-5 font-size-bolder">{{$t("USERDETAIL.PROPOSTEEFFETTUATE")}}</span>
              </b-button>
              <b-collapse visible id="collapse-idee">
                <div class="card-body">
                  <div class="row px-5">
                    <div class="col-lg-12 col-sm-12">
                      <div
                          class="row mt-5 mb-5"
                          v-for="(item) in userIdeas"
                          :key="item.id"
                      >
                        <div class="card w-100 shadow-sm">
                          <div class="card-body py-6">
                            <div class="row w-100 align-items-center">
                              <div class="col-sm-6 col-md-8 col-lg-8">
                                <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2">
                                  <div class="text-muted">{{ item.createdDate | moment("DD/MM/Y") }}</div>
                                  <h4 class="font-weight-bold mb-0">{{item.title}}</h4>
                                </div>
                              </div>
                              <div class="col-sm-6 col-md-4 col-lg-4">
                                <div class="row justify-content-end">
                                  <router-link
                                      :to="{
                                    name: 'processModuleIdeaDetail',
                                    params: {
                                      id: item.id,
                                      process_id: item.processId,
                                      phase_id: item.processPhaseId,
                                      module_id: item.process2ModuleId,
                                      /*phase_status_id: */
                                    }
                                  }"
                                  >
                                    <div class="d-flex flex-row btn btn-md btn-primary font-weight-bolder text-uppercase rounded align-items-center">
                                      <span class="d-none d-sm-block">{{$t("PSACTIVITIES.VISUALIZZA")}}</span> <i class="fas fa-angle-double-right pb-1 pr-0  ml-1"></i>
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <!--::END Proposte effettuate -->


            <!--            <div class="card mb-3 shadow-sm">
                          <b-button v-b-toggle.collapse-behaviour class="btn btn-block btn-white text-primary p-4 text-left">
                            <span class="svg-icon svg-icon-primary svg-icon-2x">
                              <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                              >
                              <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                              >
                                  <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                  <path
                                  d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961
                                  C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686
                                  20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429
                                  12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721
                                  L12.2928955,6.70710318 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  ></path>
                                  <path
                                  d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068
                                  C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322
                                  C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459
                                  C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465
                                  14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  opacity="0.3"
                                  transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                                  ></path>
                              </g>
                              </svg>
                            </span>
                            <span class="h4 ml-5 font-size-bolder">Comportamento</span>
                          </b-button>
                          <b-collapse id="collapse-behaviour">
                            <div class="card-body">
                              <div class="row p-5">
                                <div class="col-lg-12 mb-7">
                                  <h5>Atteggiamento </h5>
                                  <div class="card-text h4 font-weight-normal mt-4" v-html="selectedUser.behavior ? selectedUser.behavior : '-&#45;&#45;'"></div>
                                </div>
                                <div class="col-lg-12 mb-7">
                                  <h5>Political vision</h5>
                                  <div class="card-text h4 font-weight-normal mt-4"
                                  v-html="selectedUser.politicalView ? selectedUser.politicalView : '-&#45;&#45;'"></div>
                                </div>
                                <div class="col-lg-12 mb-7">
                                  <h5>Informazione varie</h5>
                                  <div class="card-text h4 font-weight-normal mt-4"
                                  v-html="selectedUser.politicalView ? selectedUser.politicalView : '-&#45;&#45;'"></div>
                                </div>
                              </div>
                            </div>
                          </b-collapse>
                        </div>-->
          </div>

            <div class="col-xl-8" v-else>
              <div class="card card-custom  card-shadow mb-3">
                <div class="card-body">
<!--                  <div class="d-flex align-items-center mt-4 ml-4" v-if="selectedUser.profilePublic || selectedUser.id == this.currentUser.id"-->

<!--                  >-->
<!--                    &lt;!&ndash;                  v-if="selectedUser.profilePublic || selectedUser.id == this.currentUser.id"&ndash;&gt;-->
<!--                    <a href="#" class="font-size-h2 text-dark font-weight-bolder mb-1">-->
<!--                      {{ selectedUser.username }}</a>-->
<!--                  </div>-->
                  <div class="d-flex align-items-center mt-4 ml-4">
                    <!--                  v-if="selectedUser.profilePublic || selectedUser.id == this.currentUser.id"-->
                    <span class="font-size-h2 text-dark font-weight-bolder mb-1">
                      {{ selectedUser.username }}</span>
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

import {VueEditor} from "vue2-editor";

export default {
  name: "UserDetail",
  components: {
    VueEditor,
  },
  data() {
    return {
      selectedUser: {},
      userIdeas: [],
      name: this.$t("USERDETAIL.UTENTE"),
      id: this.$route.params.id,
      group_id: this.$route.params.group_id,
      isFetching: true,
      roles: []
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "getOrganization"
    ]),
    getGenderLabel() {
      return this.selectedUser.gender == 'M' ? this.$t("USERDETAIL.M") : this.$t("USERDETAIL.F");
    },

    isItFetching() {
      return this.isFetching;
    },
  },

  created() {
    this.getUser();
    this.getIdeas();
  },
  methods: {
    getUser() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("users-expanded/" + this.id, true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            this.selectedUser = res.data;           
            this.name = (this.selectedUser?.name ?? '') + ' ' + (this.selectedUser?.surname ?? '');
            this.isFetching = false;
            this.roles = this.selectedUser.roles.filter((x) => x.authority == "EXPERT" || x.authority == "ASSESSOR")
            this.$isLoading(false)

            this.$store.dispatch(SET_BREADCRUMB, [
              { title: this.$t("USERDETAIL.COMMUNITY"), route: "/groupsCommunity" },
              { title: this.name }
            ]);
          })
          .catch(() => {
            this.$isLoading(false)
            reject(0);
          });
      });
    },

    getIdeas() {
      let qs = "?authorId.equals=" + this.id + "&organizationId.equals=" + this.getOrganization.data.id + "&fks_=istatus";

      //Visualizza solo le idee pubbliche
      qs += "&ideaStatusId.equals=3";

      //Visualizza solo la verisiona pubblicata
      qs += "&versionCurrent.equals=true";

      return new Promise((resolve, reject) => {
        let endpoint =  this.generateUrl('ideas', true);
        ApiService.get(this.$apiResourceOpen, endpoint + qs)
          .then(res => {
            this.userIdeas = res.data;            
          })
          .catch(() => {
            reject(0);
          });
      });
    },

  }

};
</script>

<style scoped></style>
